import * as React from "react"
import { useEffect } from 'react';
import { Link } from "gatsby"
import Layout from "../../templates/layout-standard"
import Seo from "../../components/seo"
import AboutNav from '../../components/nav/About';
import SideBar from '../../components/sidebar';
import UpNextCardNonStrapi from '../../components/ui/UpNextCardNonStrapi';
import "../../assets/css/screen.css"

function toggleAccordion(event) {
	let accordionContainer = this.nextElementSibling;
	this.classList.toggle("open");
	if (this.classList.contains('open')) {
		accordionContainer.setAttribute("aria-hidden", "false");
	} else {
		accordionContainer.setAttribute("aria-hidden", "true");
	}

	if (!accordionContainer.classList.contains('open')) {
		accordionContainer.classList.add('open');
		accordionContainer.style.height = 'auto';
	
		let height = accordionContainer.clientHeight + 'px';
	
		accordionContainer.style.height = '0px';
	
		setTimeout(function () {
			accordionContainer.style.height = height;
		}, 0);
	} else {
		accordionContainer.style.height = '0px';		
		accordionContainer.addEventListener('transitionend', function () {
			accordionContainer.classList.remove('open');
		}, {
			once: true
		});
	}
}

const FAQ = ({ location }) => {

	useEffect(() => {
		document.querySelectorAll(".accordiontrigger").forEach(trigger => {
			trigger.removeEventListener('click', toggleAccordion);
			trigger.addEventListener('click', toggleAccordion);
		});
	});

	return (
		<Layout location={location} heading1="FAQs" heading2="Answers to questions you may have.">

			<Seo title="FAQs | About" meta={[{description: 'Get answers to commonly asked questions about UVM Health Advantage plans.'}]} bodyclass="about faq subpage" />
			<AboutNav />
				
			<div className="constrained">
				<div className="content-constrained">
					<div className="flexwrap_subpage">
					
						<main>
							<h3>You have questions, we have answers.</h3>

							<div className="accordion">
								<div className="accordion-item">
									<button type="button" id="accordion-trigger-1" className="accordiontrigger" aria-controls="accordion-content-1">What is UVM Health Advantage?</button>
									<div id="accordion-content-1" className="accordion-content" aria-labelledby="accordion-trigger-1">
										<div className="contentwrap">
											<p>UVM Health Advantage is a new kind of Medicare Advantage plan. One that&rsquo;s been guided by doctors from the UVM Health Network and Medicare experts at MVP Health Care<sup>®</sup>.</p>
										</div>
									</div>
								</div>

								<div className="accordion-item">
									<button type="button" id="accordion-trigger-2" className="accordiontrigger" aria-controls="accordion-content-2">Where and when is UVM Health Advantage available?</button>
									<div id="accordion-content-2" className="accordion-content" aria-labelledby="accordion-trigger-2">
										<div className="contentwrap">
											<p>UVM Health Advantage is available across all of Vermont and in Clinton, Essex, Franklin, Hamilton, and St. Lawrence counties in New York.</p>
										
											<p>Eligible Medicare consumers can enroll during the Initial Enrollment Period when first becoming eligible for Medicare or during the Annual Open Enrollment Period. The Initial Enrollment Period is a seven-month window starting three months before you turn 65 to three months after your birthday month. The Annual Open Enrollment Period occurs between October 15 and December 7 each year. You can also make changes to coverage when certain events happen in your life, like if you move or you lose other insurance coverage (known as Special Enrollment Periods).</p>
										</div>
									</div>
								</div>
			
								<div className="accordion-item">
									<button type="button" id="accordion-trigger-3" className="accordiontrigger" aria-controls="accordion-content-3">Why did the UVM Health Network and MVP Health Care join together to create a Medicare Advantage plan?</button>
									<div id="accordion-content-3" className="accordion-content" aria-labelledby="accordion-trigger-3">
										<div className="contentwrap">
											<p>After hearing from more than 1,000 Medicare-eligible community members from across Vermont and northern New York, the providers of the UVM Health Network and the Medicare experts at MVP Health Care identified a need for a Medicare Advantage plan in this region that would make health care and simpler and easier to use. This led to the creation of UVM Health Advantage.</p>
										</div>
									</div>
								</div>
				
								<div className="accordion-item">
									<button type="button" id="accordion-trigger-4" className="accordiontrigger" aria-controls="accordion-content-4">How can I learn more?</button>
									<div id="accordion-content-4" className="accordion-content" aria-labelledby="accordion-trigger-4">
										<div className="contentwrap">
											<p><Link to="/plans/uvm-health-advantage/">Visit Plans</Link> to see your options and benefit details, or <Link to="/request-a-kit/">Request A Kit</Link> to have this information conveniently mailed to your home. You can also speak with one of our expert UVM Health Advantage Plan Guides by calling <span className="phone_desktop"><b>1-833-368-4592</b></span><a className="phone_mobile" href="tel:18333684592"><b>1-833-368-4592</b></a>, October 1-March 31, seven days a week, 8&nbsp;a.m.-8&nbsp;p.m. Eastern Time. April 1-September 30, Monday-Friday, 8&nbsp;a.m.-8&nbsp;p.m.</p>
											<p><a href="https://www.mvphealthcare.com/-/media/project/mvp/healthcare/documents/aep/2024/star-ratings/h9615-medicare-star-rating-2024-english.pdf" className="download">View details on the 2024 Star Ratings (PDF)</a></p>
										</div>
									</div>
								</div>
					
								<div className="accordion-item">
									<button type="button" id="accordion-trigger-5" className="accordiontrigger" aria-controls="accordion-content-5">What if I currently have a Medicare Advantage plan from MVP?</button>
									<div id="accordion-content-5" className="accordion-content" aria-labelledby="accordion-trigger-5">
										<div className="contentwrap">
											<p>Rest assured: your MVP Medicare Advantage plan and all benefit details will remain exactly the same through the remainder of 2023, but some of our plans in your area may be changing or retiring in 2024 (you should receive a letter informing you about any changes). Good news: you will have the option to enroll in one of our new UVM Health Advantage plans for 2024 &mdash; featuring all the coverage you have now, plus more! To discuss your options and find the right plan for you, please call the UVM Health Advantage Plan Guide at <span className="phone_desktop"><b>1-833-368-4592</b></span><a className="phone_mobile" href="tel:18333684592"><b>1-833-368-4592</b></a>, October 1-March 31, seven days a week, 8&nbsp;a.m.-8&nbsp;p.m. Eastern Time. April 1-September 30, Monday-Friday, 8&nbsp;a.m.-8&nbsp;p.m.</p>
										</div>
									</div>
								</div>
				
								<div className="accordion-item">
									<button type="button" id="accordion-trigger-6" className="accordiontrigger" aria-controls="accordion-content-6">What if I don&apos;t have a UVM Health Network doctor? Can I visit other doctors and hospitals?</button>
									<div id="accordion-content-6" className="accordion-content" aria-labelledby="accordion-trigger-6">
										<div className="contentwrap">
											<p>UVM Health Advantage gives you access to all the providers of the UVM Health Network, but it also includes MVP&rsquo;s broader Vermont - New York network of more than 54,000 doctors, hospitals and other providers. <Link to="/find-a-doctor/">Search our doctor network here</Link>.</p>
										</div>
									</div>
								</div>
				
								<div className="accordion-item">
									<button type="button" id="accordion-trigger-7" className="accordiontrigger" aria-controls="accordion-content-7">Is there someone I can speak with to learn more?</button>
									<div id="accordion-content-7" className="accordion-content" aria-labelledby="accordion-trigger-7">
										<div className="contentwrap">
											<p>Our UVM Health Advantage Plan Guides will listen to your needs and help you understand your options so that you can make a well-informed decision and ensure no disruption to your care. After you find the right plan, our Plan Guides will make sure your transition to UVM Health Advantage goes smoothly.</p>
										</div>
									</div>
								</div>
					
								<div className="accordion-item">
									<button type="button" id="accordion-trigger-8" className="accordiontrigger" aria-controls="accordion-content-8">If I currently have a Medigap (Medicare Supplement) plan, why should I consider UVM Health Advantage?</button>
									<div id="accordion-content-8" className="accordion-content" aria-labelledby="accordion-trigger-8">
										<div className="contentwrap">
											<p>Ask yourself: am I getting enough value for what I pay now? With UVM Health Advantage, you get benefits and support that go beyond Medigap. Such as: prescription drugs, dental, vision and hearing aids. Plus virtual care, free rides to appointments and free meal delivery after a hospital stay &mdash; removing the obstacles between you and your care. And our plans start at $0 per month. <Link to="/meet-with-us/">Contact one of our Plan Guides</Link> to discuss your personal health needs or <Link to="/medicare-basics/">learn more about Medicare Basics</Link>.</p>
										</div>
									</div>
								</div>
			
								<div className="accordion-item">
									<button type="button" id="accordion-trigger-9" className="accordiontrigger" aria-controls="accordion-content-9">If I already have original or basic Medicare, why should I consider UVM Health Advantage?</button>
									<div id="accordion-content-9" className="accordion-content" aria-labelledby="accordion-trigger-9">
										<div className="contentwrap">
											<p>Simply put: you may be missing out. With UVM Health Advantage, you get benefits that go beyond original Medicare. Such as: prescription drugs, dental, vision and hearing. Plus virtual care, free rides to appointments and free meal delivery after a hospital stay &mdash; removing the obstacles between you and your care. And our plans start at $0 per month. <Link to="/meet-with-us/">Contact one of our Plan Guides</Link> to discuss your personal health needs or <Link to="/medicare-basics/">learn more about Medicare Basics</Link>.</p>
										</div>
									</div>
								</div>
							</div>

						</main>
						
						<SideBar location={location} />
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default FAQ
